	$(document).ready(function(){
		setPxLength();
	
		//toggle article cart
		$(".cart_icon").on("click", function(){		
			check();
			toggleCart();	
		});
		
		//toggle toolbar menus
		var set = null;
		$(".header-nav a").on("click", function(){
			var toggled = check();
			$(".cart_content").hide();
			//do only if toggled item is not the clicked one
			if(toggled != this){
				toggleDown(this);
			}
		});
		
		//show/hide cart
		function toggleCart(){
			var height =$(window).height()-140;
			$(".cart_content").slideToggle( "slow", function() {
				// Animation complete.
				if($(window).width() <= 960){
					$(".cart-items").toggleClass("menuOut");
					$("body").toggleClass("fix");
					if($(".cart-items").hasClass("menuOut")){
						$(".cart-items").css("height",height);
					} else {
						$(".cart-items").css("height","100%");
					}
				}
			}).css("display","inline-block");	
		}
		
		//toggles the toolbar menus
		function toggleDown(element){
			$(element).toggleClass("open");
			var item = $(element).attr("id");
			
			if($("."+item).hasClass("down")){
				$(this).toggleClass("down");
				$(this).css("height","auto");	
			}	
			
			$("."+item).children("div.content_container").slideToggle( "slow", function() {
			}).css({"display":"inline-block", "min-width":"300px"});
		}
		
		
		//checks if another toolbar menu is open- returns toggled item
		function check(){
		var toggled = null;
			$(".header-nav a").each(function(){
				if($(this).hasClass("open")){
				
				//set the actual attribute as toggled
				toggled = this;
						toggleDown(this);	
				}
			});
			return toggled;
		}
		
		/*set menu whitespace if less than 10 menu items*/
		function setPxLength(){
			var count = 0;
			$(".ui-menu-960").children("li").each(function(){
				count+=1;
			});
			
			//calculate length for each item;
			var length = 960/count;
			
			//set length
			$(".ui-menu-960").find("li").each(function(){
				$(this).css("width",length);
			});
		}
		
		/*Menu Controller*/
		var  element = "";
		$("li.ui-menu-cat_elem").hover(function(){
			removeTrans();
			var menuItem = $(this).attr("data-menu");
			var menuItemObj = $(this);
			$(".ui-menu-subitems").find(".ui-menu-category").each(function(){
				$(this).removeClass("menuShow");
				if ($(this).attr("data-menu-for") === menuItem){
					$(this).addClass("menuShow");
					removeTrans();
					menuItemObj.addClass("transparent");
					element =  $(this);
				}
			});
		})
		
		$(".ui-menu-category").mouseleave(function() {
			removeTrans()
			$(this).removeClass("menuShow");
		});
		
		
		function removeTrans(){
			$(".ui-menu-elemente").find("li").each(function(){
				if($(this).hasClass("transparent")){
					$(this).removeClass("transparent");
				}
			});	
		}
	});
	
	/*
	function changeInnoToolbar(iElement){		
		if ($(window).scrollTop() >= 300) { 
			iElement.addClass("ui-toolbar-smallSlide");
			iElement.animate({"width": "960px"},500);
			$(".section-m").animate({"width": "960px"},500);
		} else {
			curWidth = iElement.width();
			autoWidth = iElement.css('width', 'auto').width();
			iElement.removeClass("ui-toolbar-smallSlide");
			iElement.width(curWidth).animate({width: autoWidth}, 500);
			$(".section-m").width(curWidth).animate({width: autoWidth}, 500);
		}	
	};


	$(document).ready(function(){ 
	 
		$innoToolbarElement = $('#inner');
		var curWidth = $innoToolbarElement.width();
		var autoWidth = $innoToolbarElement.css('width', 'auto').width();
	  
		$(window).resize(function(){
			changeInnoToolbar($innoToolbarElement);
		}); 
		
		$(".ui-iwc-acc-side-menu-v2>.ui-iwc-acc-cont-v2").addClass("side-menu");
		$(".ui-menu-wrapper").after($(".ui-iwc-acc-side-menu-v2").html());
		$(".ui-iwc-acc-side-menu-v2").remove();
		//$(".ui-menu-wrapper>.ui-iwc-acc-cont-v2").addClass("side-menu");
	});
	*/